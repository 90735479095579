import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import PublishIcon from "@mui/icons-material/Publish";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import { alertMessage } from "../actions/auth";
import TextField from "@mui/material/TextField";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";

import { API_URL } from "../actions/auth";

import { themeStyle, themeStyleDark } from "../components/Components";

function Media() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [medias, setMedias] = useState([]);
  const [ogMedias, setOGMedias] = useState([]);
  const [random, setRandom] = useState(0);
  const [search, setSearch] = useState("");
  const [media, setMedia] = useState({});
  const [uploadType, setUploadType] = useState("VIDEO");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [linkName, setLinkName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  function handleSearch(text) {
    setSearch(text);
    if (text.length == 0) {
      setMedias(ogMedias);
      setSearch("");
      setRandom(makeid(5));
    } else {
      var tempMedias = [];
      for (var i = 0; i < ogMedias.length; i++) {
        console.log(ogMedias[i]);
        if (
          ogMedias[i]["link_name"].toLowerCase().includes(text) ||
          ogMedias[i]["description"].toLowerCase().includes(text)
        ) {
          tempMedias.push(ogMedias[i]);
        }
      }

      setCurrentPage(1);
      setMedias(tempMedias);
      setRandom(makeid(5));
    }
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function handleCurrentPage(val) {
    var tempUsers = Array.from(ogMedias);
    setSearch("");
    setCurrentPage(val);
    setMedias(tempUsers.splice((val - 1) * 10, 10));
  }

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "UPLOAD"
  );

  function getData() {
    var body = {
      uid: state.auth.user.uid,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "get_media_content", body, config).then((res) => {
      setOGMedias(res.data);
      setMedia(res.data[0]);
      setMedias(res.data.splice(0, 10));
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  const handleUploadType = (event) => {
    setUploadType(event.currentTarget.value);
  };
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const [isUploading, setIsUploading] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onFileUpload() {
    setIsUploading(true);
    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);

    const config = {
      headers: {
        Authorization: state.auth.token,
      },
    };

    formData.append("phone_number", state.auth.user.phone_number);
    formData.append("link_name", linkName);
    formData.append("description", description);
    formData.append("link_type", uploadType);

    if (uploadType == "VIDEO") {
      formData.append("link", youtubeLink);
    }

    axios
      .post(API_URL + "update_xl", formData, config)
      .then(() => {
        createAlert("SUCCESS", "Success.", "Document uploaded.");
        getData();
      })
      .catch((err) => {
        console.log("___");
        console.log(err);
        createAlert("ERROR", "Error", "An error occured.");
      });

    handleClose();
    document.getElementById("contained-button-file").value = "";
  }

  function onFileChange(event) {
    // Update the state
    setSelectedFile(event.target.files[0]);

    handleOpen();
  }

  function onFileClick(event) {
    // Update the state
    document.getElementById("contained-button-file").value = "";
  }

  function fileData() {
    if (selectedFile) {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton size="small">
              <CloseIcon
                onClick={() => setOpen(false)}
                style={{ color: "#3182bd" }}
              />
            </IconButton>
          </div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>

          <br></br>
          <br></br>
          {selectedFile.size > 25 * 1024 * 1024 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  createAlert(
                    "ERROR",
                    "File too big.",
                    "File size should be less than 25 MB."
                  );
                  setOpen(false);
                }}
                variant="contained"
                style={{ backgroundColor: "#ddd" }}
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={() => onFileUpload()}
                color="primary"
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  }

  function getContent() {
    if (selectedPage == "MEDIA") {
      return (
        <Fragment>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="Search Media"
            variant="outlined"
            value={search}
            size="small"
            sx={{
              input: {
                fontSize: "24px",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              },
            }}
            style={{
              height: "55px",
              borderRadius: "10px",
              border:
                state.auth.theme == "dark"
                  ? "2px solid " + themeStyleDark.primaryColor
                  : "2px solid " + themeStyle.primaryColor,
              backgroundColor:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryCardColor
                  : themeStyle.primaryCardColor,
             boxShadow: "0px 0px 10px #ccc",

              marginBottom: "30px",
            }}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "25px",
            }}
          >
            {Array.from(Array(Math.ceil(ogMedias.length / 100)).keys()).map(
              (temp, index) => (
                <div
                  style={{
                    cursor: "pointer",
                    margin: "3px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "25px",
                    height: "25px",
                    borderRadius: "30px",
                    color: "white",
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                  onClick={() => {
                    handleCurrentPage(index + 1);
                  }}
                >
                  {index + 1}
                </div>
              )
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <Grid container spacing={3} justifyContent={"flex-end"}>
              <Grid item md={8}>
                <div
                  style={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryCardColor
                        : themeStyle.primaryCardColor,
                    borderRadius: "10px",
                    padding: "15px",
                   boxShadow: "0px 0px 10px #ccc",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    style={{
                      justifyContent: "flex-start",
                      textAlign: "left",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      paddingBottom: "20px",
                      borderBottom:
                        state.auth.theme == "dark"
                          ? "1px solid" + themeStyleDark.primaryColor
                          : "1px solid" + themeStyle.primaryColor,
                    }}
                  >
                    <Grid item md={1}>
                      No.
                    </Grid>
                    <Grid item md={4}>
                      Media Name
                    </Grid>
                    <Grid item md={3}>
                      Media link
                    </Grid>

                    <Grid item md={1}>
                      Type
                    </Grid>
                  </Grid>
                  {medias.length == 0 ? (
                    <h1>No medias found.</h1>
                  ) : (
                    <Fragment>
                      {medias.map((media, index) => (
                        <Grid
                          onClick={() => setMedia(media)}
                          container
                          spacing={2}
                          style={{
                            color: "black",
                            padding: "15px",
                          }}
                        >
                          <Grid item md={1}>
                            {(currentPage - 1) * 10 + (index + 1)}
                          </Grid>
                          <Grid
                            style={{ cursor: "pointer" }}
                            onClick={() => {}}
                            item
                            md={3}
                          >
                            {media["link_name"]}
                          </Grid>
                          <Grid item md={4}>
                            <Link to={media.link}> {media["link"]}</Link>
                          </Grid>

                          <Grid item md={1} style={{ textAlign: "center" }}>
                            {media["type"]}
                          </Grid>
                        </Grid>
                      ))}
                    </Fragment>
                  )}
                </div>
              </Grid>
              <Grid item md={4}>
                <div
                  style={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryCardColor
                        : themeStyle.primaryCardColor,
                    borderRadius: "10px",
                    padding: "15px",
                    height: "100%",
                   boxShadow: "0px 0px 10px #ccc",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "15px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  >
                    Selected Media
                  </div>
                  {media.link_name}
                  <br />
                  {media.type}
                  <br />
                  <Link to={media.link}>{media.link}</Link>
                  <br />
                  {media.description}
                </div>
              </Grid>
            </Grid>
          </div>
        </Fragment>
      );
    } else {
      if (uploadType == "VIDEO") {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryCardColor
                    : themeStyle.primaryCardColor,
                borderRadius: "10px",
                padding: "15px",
               boxShadow: "0px 0px 10px #ccc",
                width: "50%",
              }}
            >
              <Grid container spacing={1.5} justifyContent={"flex-start"}>
                <Grid item md={4}>
                  {uploadType == "VIDEO" ? (
                    <Button
                      fullWidth
                      value={"VIDEO"}
                      onClick={handleUploadType}
                      p={2}
                      style={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        color: "white",
                        height: "30px",
                      }}
                      borderRadius={6}
                    >
                      <h3
                        style={{
                          marginTop: "17px",
                          fontSize: "15px",
                        }}
                      >
                        VIDEO
                      </h3>
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      value={"VIDEO"}
                      onClick={handleUploadType}
                      p={2}
                      style={{
                        backgroundColor: "#d7ecff",
                        color: "white",
                        height: "30px",
                      }}
                      borderRadius={6}
                    >
                      <h3
                        style={{
                          marginTop: "17px",
                          fontSize: "15px",
                          color: "#084594",
                        }}
                      >
                        VIDEO
                      </h3>
                    </Button>
                  )}
                </Grid>
                <Grid item md={4}>
                  {uploadType == "DOCUMENT" ? (
                    <Button
                      fullWidth
                      value="DOCUMENT"
                      onClick={handleUploadType}
                      p={2}
                      style={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        color: "white",
                        height: "30px",
                      }}
                      borderRadius={6}
                    >
                      <h3
                        style={{
                          marginTop: "17px",
                          fontSize: "15px",
                        }}
                      >
                        DOCUMENT / FILE / IMAGE
                      </h3>
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      value="DOCUMENT"
                      onClick={handleUploadType}
                      p={2}
                      style={{
                        backgroundColor: "#d7ecff",
                        color: "white",
                        height: "30px",
                      }}
                      borderRadius={6}
                    >
                      <h3
                        style={{
                          marginTop: "17px",
                          fontSize: "15px",
                          color: "#084594",
                        }}
                      >
                        DOCUMENT / FILE / IMAGE
                      </h3>
                    </Button>
                  )}
                </Grid>
              </Grid>
              <br />
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Video Name"
                variant="outlined"
                value={linkName}
                size="small"
                onChange={(e) => setLinkName(e.target.value)}
              />
              <br />
              <br />

              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Video Link"
                variant="outlined"
                value={youtubeLink}
                size="small"
                onChange={(e) => setYoutubeLink(e.target.value)}
              />
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{
                    fontSize: "20px",
                    height: "35px",
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                  onClick={() => {}}
                  variant="contained"
                  color="primary"
                >
                  <span
                    style={{
                      color: "white",
                    }}
                  >
                    UPLOAD
                  </span>
                  <PublishIcon
                    style={{
                      fontSize: "30px",
                      color: "white",
                      marginRight: "-10px",
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryCardColor
                    : themeStyle.primaryCardColor,
                borderRadius: "10px",
                padding: "15px",
               boxShadow: "0px 0px 10px #ccc",
                width: "50%",
              }}
            >
              <Grid container spacing={1.5} justifyContent={"flex-start"}>
                <Grid item md={4}>
                  {uploadType == "VIDEO" ? (
                    <Button
                      fullWidth
                      value={"VIDEO"}
                      onClick={handleUploadType}
                      p={2}
                      style={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        color: "white",
                        height: "30px",
                      }}
                      borderRadius={6}
                    >
                      <h3
                        style={{
                          marginTop: "17px",
                          fontSize: "15px",
                        }}
                      >
                        VIDEO
                      </h3>
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      value={"VIDEO"}
                      onClick={handleUploadType}
                      p={2}
                      style={{
                        backgroundColor: "#d7ecff",
                        color: "white",
                        height: "30px",
                      }}
                      borderRadius={6}
                    >
                      <h3
                        style={{
                          marginTop: "17px",
                          fontSize: "15px",
                          color: "#084594",
                        }}
                      >
                        VIDEO
                      </h3>
                    </Button>
                  )}
                </Grid>
                <Grid item md={4}>
                  {uploadType == "DOCUMENT" ? (
                    <Button
                      fullWidth
                      value="DOCUMENT"
                      onClick={handleUploadType}
                      p={2}
                      style={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        color: "white",
                        height: "30px",
                      }}
                      borderRadius={6}
                    >
                      <h3
                        style={{
                          marginTop: "17px",
                          fontSize: "15px",
                        }}
                      >
                        DOCUMENT / FILE / IMAGE
                      </h3>
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      value="DOCUMENT"
                      onClick={handleUploadType}
                      p={2}
                      style={{
                        backgroundColor: "#d7ecff",
                        color: "white",
                        height: "30px",
                      }}
                      borderRadius={6}
                    >
                      <h3
                        style={{
                          marginTop: "17px",
                          fontSize: "15px",
                          color: "#084594",
                        }}
                      >
                        DOCUMENT / FILE / IMAGE
                      </h3>
                    </Button>
                  )}
                </Grid>
              </Grid>
              <br />
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="File title"
                variant="outlined"
                value={title}
                size="small"
                onChange={(e) => setTitle(e.target.value)}
              />
              <br />
              <br />

              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="File Description"
                variant="outlined"
                value={description}
                size="small"
                onChange={(e) => setDescription(e.target.value)}
              />
              <br />
              <br />
              <label
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                htmlFor="contained-button-file"
              >
                <input
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  onChange={(e) => onFileChange(e)}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ maxHeight: "148px", margin: "15px 0px 15px 0px" }}
                    src={require("../img/pdf.png")}
                  />
                  <img
                    style={{ maxHeight: "128px", margin: "15px 0px 15px 0px" }}
                    src={require("../img/img.png")}
                  />
                  <img
                    style={{
                      maxHeight: "128px",
                      margin: "15px 15px 15px 15px",
                    }}
                    src={require("../img/xl.png")}
                  />
                  <img
                    style={{ maxHeight: "128px", margin: "15px 0px 15px 0px" }}
                    src={require("../img/doc.png")}
                  />
                </div>
                <Button
                  style={{
                    width: "550px",
                    fontSize: "20px",
                    height: "40px",
                    marginTop: "15px",
                    backgroundColor: themeStyle.primaryColor,
                    fontFamily: "Bahnschrift",
                  }}
                  variant="contained"
                  component="span"
                >
                  Upload DOCUMENT / FILE / IMAGE
                </Button>
                <br />
                <br />
                {isUploading ? <CircularProgress /> : ""}
              </label>
            </div>
          </div>
        );
      }
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                maxWidth: "88vw",
                marginTop: "45vh",
                marginLeft: "8vw",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar
              tabs={
                <Grid container spacing={1.5} justifyContent={"flex-end"}>
                  <Grid item md={4}>
                    {selectedPage == "MEDIA" ? (
                      <Button
                        fullWidth
                        value={"MEDIA"}
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          MEDIA
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value={"MEDIA"}
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#d7ecff",
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: "#084594",
                          }}
                        >
                          MEDIA
                        </h3>
                      </Button>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    {selectedPage == "UPLOAD" ? (
                      <Button
                        fullWidth
                        value="UPLOAD"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          UPLOAD
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value="UPLOAD"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#d7ecff",
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: "#084594",
                          }}
                        >
                          UPLOAD
                        </h3>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              }
            />
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    backgroundColor: "white",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    padding: "20px",
                  }}
                >
                  {fileData()}
                </div>
              </Fade>
            </Modal>
            <div
              style={{
                padding: "100px 50px 90px 115px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                minHeight: "100vh",
                width: "100vw",
              }}
            >
              {getContent()}
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.backgroundColor
              : themeStyle.backgroundColor,
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Media;
