import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import QRCode from "qrcode";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Legend,
  scales,
} from "chart.js";
import { API_URL } from "../actions/auth";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import { themeStyle, themeStyleDark } from "../components/Components";

function Reports() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [ogUsers, setOGusers] = useState([]);
  const [random, setRandom] = useState(0);
  const [search, setSearch] = useState("");
  const [profile, setProfile] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  function handleSearch(text) {
    setSearch(text);
    if (text.length == 0) {
      setUsers(ogUsers);
      setSearch("");
      setRandom(makeid(5));
    } else {
      var tempUsers = [];

      for (var i = 0; i < ogUsers.length; i++) {
        if (
          ogUsers[i]["full_name"].includes(text) ||
          ogUsers[i]["email"].includes(text)
        ) {
          tempUsers.push(ogUsers[i]);
        }
      }
      setCurrentPage(1);
      setUsers(tempUsers);
      setRandom(makeid(5));
    }
  }

  const graphState = {
    dataLine: {
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      datasets: [
        {
          label: "Users per month",
          fill: false,
          lineTension: 0.5,
          borderRadius: 0,
          borderSkipped: false,
          backgroundColor: "rgba(8, 160, 69, 0.8)",
          borderColor: "rgba(8, 160, 69, 0.8)",
          pointRadius: 0,
          borderDash: [],
          borderDashOffset: 0.0,
          data: [66, 44, 66, 55, 44, 45, 77, 55, 66, 66, 55, 77],
          barThickness: 30,
          borderWidth: 2,
        },
        {
          label: "Sales per month",
          fill: false,
          lineTension: 0.5,
          borderRadius: 0,
          borderSkipped: false,
          backgroundColor: "rgba(0, 101, 194, 0.8)",
          borderColor: "rgba(0, 101, 194, 0.8)",

          borderDash: [],
          borderWidth: 2,
          borderDashOffset: 0.0,
          data: [55, 50, 50, 81, 56, 55, 65, 59, 80, 44, 56, 33],
          barThickness: 30,
          pointRadius: 0,
        },
      ],
    },
  };

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function handleCurrentPage(val) {
    var tempUsers = Array.from(ogUsers);
    setSearch("");
    setCurrentPage(val);
    setUsers(tempUsers.splice((val - 1) * 25, 25));
  }

  function getData() {
    var body = {
      uid: state.auth.user.uid,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "get_users", body, config).then((res) => {
      setOGusers(res.data);
      setProfile(res.data[0]);
      setUsers(res.data.splice(0, 25));
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                maxWidth: "88vw",
                marginTop: "45vh",
                marginLeft: "8vw",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar />
            {users.length == 0 ? (
              <div
                style={{
                  padding: "100px 50px 90px 115px",
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.backgroundColor
                      : themeStyle.backgroundColor,
                  minHeight: "100vh",
                  width: "100vw",
                  fontSize: "30px",
                }}
              >
                No Users yet
              </div>
            ) : (
              <div
                style={{
                  padding: "100px 50px 90px 115px",
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.backgroundColor
                      : themeStyle.backgroundColor,
                  minHeight: "100vh",
                  width: "100vw",
                }}
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Search User / Email"
                  variant="outlined"
                  value={search}
                  size="small"
                  sx={{
                    input: {
                      fontSize: "24px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    },
                  }}
                  style={{
                    height: "55px",
                    borderRadius: "10px",
                    border:
                      state.auth.theme == "dark"
                        ? "2px solid " + themeStyleDark.primaryColor
                        : "2px solid " + themeStyle.primaryColor,
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryCardColor
                        : themeStyle.primaryCardColor,
                    boxShadow: "0px 0px 10px #ccc",

                    marginBottom: "30px",
                  }}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <Grid container spacing={3} justifyContent={"flex-end"}>
                    <Grid item md={3}>
                      {Array.from(
                        Array(Math.ceil(ogUsers.length / 25)).keys()
                      ).map((temp, index) => (
                        <div
                          style={{
                            cursor: "pointer",
                            margin: "3px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "25px",
                            borderRadius: "30px",
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          }}
                          onClick={() => {
                            handleCurrentPage(index + 1);
                          }}
                        >
                          {index + 1}
                        </div>
                      ))}
                      <div
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryCardColor
                              : themeStyle.primaryCardColor,
                          borderRadius: "10px",
                          padding: "15px",
                          boxShadow: "0px 0px 10px #ccc",
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          style={{
                            justifyContent: "flex-start",
                            textAlign: "left",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            paddingBottom: "20px",
                            borderBottom:
                              state.auth.theme == "dark"
                                ? "1px solid" + themeStyleDark.primaryColor
                                : "1px solid" + themeStyle.primaryColor,
                          }}
                        >
                          <Grid item md={2}>
                            No.
                          </Grid>
                          <Grid item md={10}>
                            Phone Number
                          </Grid>
                        </Grid>
                        {users.map((user, index) => (
                          <Grid
                            onClick={() => setProfile(user)}
                            container
                            spacing={2}
                            style={{
                              color: "black",
                              padding: "15px",
                            }}
                          >
                            <Grid item md={2}>
                              {(currentPage - 1) * 25 + (index + 1)}
                            </Grid>
                            <Grid
                              style={{ cursor: "pointer" }}
                              onClick={() => setProfile(user)}
                              item
                              md={10}
                            >
                              {user["phone_number"]}
                            </Grid>
                          </Grid>
                        ))}
                      </div>
                    </Grid>
                    <Grid item md={9}>
                      <div
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryCardColor
                              : themeStyle.primaryCardColor,
                          marginTop: "6px",
                          padding: "15px",
                          boxShadow: "0px 0px 10px #ccc",
                          borderRadius: "10px",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "25px",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          {"Phone number: " + profile["phone_number"]}
                        </div>
                        <Bar
                          height={120}
                          width={250}
                          plugins={[zoomPlugin]}
                          data={graphState.dataLine}
                          options={{
                            plugins: {
                              zoom: {
                                zoom: {
                                  wheel: {
                                    enabled: true,
                                  },
                                  pinch: {
                                    enabled: true,
                                  },
                                  mode: "xy",
                                },
                              },
                            },
                            legend: {
                              display: false,
                            },
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            borderColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            responsive: true,
                            animation: { duration: 0 },
                            title: {
                              display: true,
                              text: "New user data",
                            },
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "25px",
                        }}
                      >
                        <Button
                          fullWidth
                          value={"VIDEO"}
                          onClick={() => {}}
                          p={2}
                          style={{
                            width: "300px",
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            color: "white",
                            height: "40px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            DOWNLOAD PDF
                          </h3>
                        </Button>
                        <Button
                          fullWidth
                          value={"VIDEO"}
                          onClick={() => {}}
                          p={2}
                          style={{
                            width: "300px",
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            color: "white",
                            marginLeft: "25px",
                            height: "40px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            DOWNLOAD CSV
                          </h3>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.backgroundColor
              : themeStyle.backgroundColor,
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Reports;
